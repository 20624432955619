<script setup lang="ts">
import { sanitizeTitle } from '@@/bits/landside_helper'
import OzBaseButton from '@@/library/v4/components/OzBaseButton.vue'
import OzDivider, { OzDividerColorScheme } from '@@/library/v4/components/OzDivider.vue'
import OzIcon from '@@/library/v4/components/OzIcon.vue'
import { useWindowSizeStore } from '@@/pinia/window_size'
import type { VueCssClass } from '@@/types'
import { storeToRefs } from 'pinia'
import { ref } from 'vue'

export interface LandsideNavigation {
  url: string
  name: string
}

withDefaults(
  defineProps<{
    title: string
    navigationItems: LandsideNavigation[]
    xDivider?: boolean
    buttonTextColorClasses?: VueCssClass
    isLightMode?: boolean
  }>(),
  {
    xDivider: true,
    buttonTextColorClasses: undefined,
    isLightMode: false,
  },
)

const isOpen = ref(false)
const { isSmallerThanDesktop } = storeToRefs(useWindowSizeStore())
</script>

<template>
  <nav class="px-2 desktop:px-0" :aria-labelledby="`${title}FooterSectionTitle`">
    <OzBaseButton
      v-if="isSmallerThanDesktop"
      :class="[
        'w-full',
        'bg-transparent',
        'my-1',
        'rounded-xl',
        'group',
        // Focus
        'focus-visible:outline-none',
        'focus-visible:ring-2',
        isLightMode
          ? {
              'focus-visible:ring-grape-500': true,
              'focus-visible:text-dark-text-100': true,
              // Active
              'hactive:text-grape-500': true,
            }
          : {
              'focus-visible:ring-grape-500 dark:focus-visible:ring-canary-500': true,
              'focus-visible:text-dark-text-100 dark:hhover:text-light-text-100': true,
              // Active
              'hactive:text-grape-500 dark:hactive:text-canary-500': true,
            },
      ]"
      :data-testid="`${title}FooterNavigationButton`"
      @click.prevent.stop="() => (isOpen = !isOpen)"
    >
      <div
        :class="[
          'flex items-center',
          'space-x-3',
          'ps-2.5 pe-3 py-2',
          'text-body',
          isLightMode ? 'text-dark-text-200' : buttonTextColorClasses || 'text-dark-text-200 dark:text-light-text-200',
          'rounded-xl',
          isLightMode
            ? {
                // Hover
                'hover-hover:group-hover:bg-button-light': true,
                'hover-hover:group-hover:text-dark-text-100': true,
                // Active
                'hover-hover:group-active:bg-button-light': true,
                'hover-hover:group-active:text-grape-500': true,
                'group-active:bg-button-light': true,
                'group-active:text-grape-500': true,
              }
            : {
                // Hover
                'hover-hover:group-hover:bg-button-light hover-hover:dark:group-hover:bg-button-dark': true,
                'hover-hover:group-hover:text-dark-text-100 hover-hover:dark:group-hover:text-light-text-100': true,
                // Active
                'hover-hover:group-active:bg-button-light hover-hover:dark:group-active:bg-button-dark': true,
                'hover-hover:group-active:text-grape-500 hover-hover:dark:group-active:text-canary-500': true,
                'group-active:bg-button-light dark:group-active:bg-button-dark': true,
                'group-active:text-grape-500 dark:group-active:text-canary-500': true,
              },
        ]"
      >
        <h2 :id="`${title}FooterSectionTitle`" :class="['flex flex-1', isOpen && 'font-semibold']">
          {{ title }}
        </h2>
        <OzIcon name="chevron_right_center" :size="14" :class="[isOpen && 'rotate-90 rtl:-rotate-90']" />
      </div>
    </OzBaseButton>
    <h2
      v-else
      :id="`${title}FooterSectionTitle`"
      :class="[
        'flex',
        'ps-2.5 mb-2',
        isLightMode ? 'text-dark-text-100' : 'text-dark-text-100 dark:text-light-text-100',
        'text-17',
        'font-semibold',
      ]"
    >
      {{ title }}
    </h2>

    <transition :name="isSmallerThanDesktop ? 'collapse' : ''" appear>
      <ul v-show="!isSmallerThanDesktop || isOpen" class="list-container">
        <li v-for="(navigationItem, index) in navigationItems" :key="index">
          <OzBaseButton
            :href="navigationItem.url"
            :class="['block w-full bg-transparent', 'py-1 desktop:py-0', 'group']"
            :data-testid="sanitizeTitle(navigationItem.name)"
          >
            <span
              :class="[
                'text-body desktop:text-body-small-posts',
                isLightMode
                  ? 'text-dark-text-200'
                  : buttonTextColorClasses || 'text-dark-text-200 dark:text-light-text-200',
                'flex',
                'px-2.5',
                'py-2 desktop:py-1',
                'rounded-xl desktop:rounded-lg',
                // Focus
                'group-focus-visible:outline-none',
                'group-focus-visible:ring-2',
                isLightMode
                  ? {
                      'group-focus-visible:ring-grape-500': true,
                      // Hover
                      'hover-hover:group-hover:text-dark-text-100': true,
                      // Active
                      'hover-hover:group-active:text-grape-500': true,
                      'group-active:text-grape-500': true,
                      // Show hover and active background for mobile only
                      'hover-hover:group-hover:bg-button-light': isSmallerThanDesktop,
                      'group-active:bg-button-light': isSmallerThanDesktop,
                    }
                  : {
                      'group-focus-visible:ring-grape-500 dark:group-focus-visible:ring-canary-500': true,
                      // Hover
                      'hover-hover:group-hover:text-dark-text-100 hover-hover:dark:group-hover:text-light-text-100': true,
                      // Active
                      'hover-hover:group-active:text-grape-500 hover-hover:dark:group-active:text-canary-500': true,
                      'group-active:text-grape-500 dark:group-active:text-canary-500': true,
                      // Show hover and active background for mobile only
                      'hover-hover:group-hover:bg-button-light hover-hover:dark:group-hover:bg-button-dark':
                        isSmallerThanDesktop,
                      'group-active:bg-button-light dark:group-active:bg-button-dark': isSmallerThanDesktop,
                    },
              ]"
            >
              {{ navigationItem.name }}
            </span>
          </OzBaseButton>
        </li>
      </ul>
    </transition>

    <OzDivider
      v-if="isSmallerThanDesktop && xDivider"
      :dark-mode="isLightMode ? false : undefined"
      :color-scheme="OzDividerColorScheme.Solid"
      class="mx-2"
    />
  </nav>
</template>

<style lang="postcss" scoped>
.collapse-enter-active,
.collapse-enter-active > .list-container {
  transition: all 0.5s ease-in;

  @media (prefers-reduced-motion) {
    transition-duration: 0s;
  }
}

.collapse-leave-active,
.collapse-leave-active > .list-container {
  transition: all 0.3s cubic-bezier(0, 1, 0, 1);

  @media (prefers-reduced-motion) {
    transition-duration: 0s;
  }
}

.collapse-enter,
.collapse-leave-to {
  overflow: hidden;
  max-height: 0px;
}

.collapse-enter-to,
.collapse-leave {
  overflow: hidden;
  /*
    We set 1000px as the max-height since 100% does not work.
    Moreover, each li has only a height of 48px so even when the
    number of li increases, it is unlikely to exceed 1000px
  */
  max-height: 1000px;
}
</style>
